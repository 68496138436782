<template>
  <div class="preview-doc-chapter">
    <el-dialog
      :visible.sync="isShowPreviewDialog"
      :show-close="false"
      @open="open"
      @close="closeDialog"
    >
      <i class="iconfont iconguanbi" @click="closeDialog"></i>
      <div class="preview-content">
        <div class="preview-content__doc">
          <div
            v-for="cid in cids"
            :key="cid"
            :id="cid"
            :class="{ isVisiblity: cid == activeCid }"
          ></div>
        </div>
        <div class="preview-content__info">
          <div
            class="player-wrap"
            v-loading="playerLoading"
            element-loading-text="初始化中..."
          >
            <div class="player" id="dialog-player"></div>
          </div>
          <div class="chapters">
            <h3><i class="iconfont iconzhangjie"></i><span>章节</span></h3>
            <ul>
              <li
                class="chapters__item"
                v-for="(chapter, index) in chapterData"
                :key="index"
              >
                <div
                  class="chapters__item__main"
                  @click="setPlayTime(chapter.created_at_second)"
                >
                  <div class="chapters__item__title">
                    <span class="index">{{ index + 1 }}</span>
                    {{ chapter.title }}
                  </div>
                  <span class="chapters__item__time">{{
                    chapter.created_at
                  }}</span>
                </div>
                <div
                  class="chapters__item__sub"
                  v-for="(subChapter, subIndex) in chapter.subsection"
                  :key="subIndex"
                  @click="setPlayTime(subChapter.created_at_second)"
                >
                  <div class="chapters__item__title">
                    <span class="index">{{ index + 1 }}-{{ subIndex }}</span>
                    <i class="iconfont iconxiaozhangjie"></i>
                    {{ subChapter.title }}
                  </div>
                  <span class="chapters__item__time">{{
                    subChapter.created_at
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { APPID, RECORDID } from '../mixin'
export default {
  props: {
    chapterData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowPreviewDialog: false,
      cids: [],
      activeCid: '',
      config: {},
      vhallplayer: null,
      playerLoading: true
    }
  },
  created() {
    window.docSdk = null
  },
  methods: {
    dialogShow() {
      this.isShowPreviewDialog = true
    },
    open() {
      if (this.config.accountId && this.config.token && this.config.appId) {
        this.initDocSdk(this.config)
      } else {
        this.getAccessToken()
      }
    },
    setPlayTime(second) {
      this.vhallplayer.setCurrentTime(second, e => {
        console.error('设置播放时间失败')
      })
    },
    closeDialog() {
      console.log(this.vhallplayer)
      this.vhallplayer && this.vhallplayer.destroy()
      window.docSdk && window.docSdk.destroyContainer()
      this.isShowPreviewDialog = false
    },
    initDocSdk(config) {
      let opt = {
        ...config,
        role: VHDocSDK.RoleType.SPECTATOR, // 角色 必须
        isVod: true, // 是否是回放 必须
        client: VHDocSDK.Client.PC_WEB // 客户端类型
      }
      let fail = e => {
        console.log(e, '失败')
      }
      let success = () => {
        this.initPlayer(this.config)
        console.log('成功')
      }
      window.docSdk = VHDocSDK.createInstance(opt, success, fail)
      window.docSdk.on(VHDocSDK.Event.VOD_CUEPOINT_LOAD_COMPLETE, async e => {
        const list = window.docSdk.getVodAllCids()
        this.cids = list.map(item => item.cid)
        await this.$nextTick()
        list.forEach(item => {
          const { type, cid } = item
          this.initContainer(type.toLowerCase(), cid)
        })
        window.docSdk.loadVodIframe()
      })
      window.docSdk.on(VHDocSDK.Event.VOD_TIME_UPDATE, async e => {
        if (e.activeId) {
          this.activeCid = e.activeId
          await this.$nextTick()
          window.docSdk.selectContainer({
            id: e.activeId
          })
        }
      })
      window.docSdk.on(VHDocSDK.Event.ALL_COMPLETE, () => {
        console.log('ALL_COMPLETE')
        this.playerLoading = false
      })
    },
    initContainer(type, cid) {
      let opt = {
        elId: cid,
        width: 700,
        height: 394,
        id: cid
      }
      if (type === 'document') {
        window.docSdk.createDocument(opt)
      }
    },
    initPlayer(config) {
      const opt = {
        ...config,
        type: 'vod',
        autoplay: false,
        videoNode: 'dialog-player',
        vodOption: {
          recordId: RECORDID
        }
      }
      const success = event => {
        this.vhallplayer = event.vhallplayer
        this.vhallplayer.openControls(true)
        this.vhallplayer.openUI(false)
      }
      const failure = function(e) {
        this.playerLoading = false
        console.log(e, '失败')
      }
      this.$nextTick(() => {
        VhallPlayer.createInstance(opt, success, failure)
      })
    },
    getAccessToken() {
      this.$fetch('getAccessToken', { app_id: APPID }).then(({ data }) => {
        this.config = {
          appId: APPID,
          accountId: data[0].accountId,
          token: data[0].token
        }
        this.initDocSdk(this.config)
      })
    }
  },
  beforeDestroy() {
    window.docSdk = null
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.preview-doc-chapter {
  position: relative;
  /deep/ .vhallPlayer-container .vhallPlayer-controller-box {
    display: none;
  }
  /deep/ .el-dialog {
    width: 1120px;
    height: 662px;
    background: #000;
    margin-top: 5vh !important;
    &__header {
      display: none;
    }
    &__body {
      height: 100%;
      padding: 0;
    }
  }
  .preview-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    &__doc {
      display: flex;
      align-items: center;
      width: 700px;
      border-right: 1px solid #363636;
      box-sizing: border-box;
      div {
        visibility: hidden;
        &.isVisiblity {
          visibility: visible;
        }
      }
    }
    // /deep/ .vhallPlayer-container {
    //   display: none;
    // }
    &__info {
      flex: 1;
      width: 420px;
      .player-wrap {
        position: relative;
        width: 100%;
        height: 236px;
        // background: rgba(0, 0, 0, 0.5);
        .player {
          width: 100%;
          height: 100%;
          background: oldlace;
        }
        &:hover .player-mask {
          display: block;
        }
        .player-mask {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.5);
          .iconfont {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .chapters {
        // margin-top: 10px;
        // padding: 0 15px;
        h3 {
          margin: 0;
          line-height: 50px;
          color: @mainColor;
          font-weight: normal;
          font-size: 14px;
          padding: 0 20px;
          text-align: center;
          span {
            padding-left: 10px;
          }
        }
        ul {
          margin: 0;
          max-height: 374px;
          overflow-y: auto;
        }
        &__item {
          color: #f7f7f7;
          cursor: pointer;
          &:hover {
            // background: ;
          }
          &__main,
          &__sub {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 0 15px;
            line-height: 50px;
          }
          &__main {
            background: #1a1a1a;
          }
          &__title {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            .index {
              display: inline-block;
              width: 30px;
            }
            i {
              margin: 0 10px;
            }
          }
          &__time {
            display: block;
            margin-left: 10px;
            width: 58px;
            color: #999999;
          }
        }
      }
    }
  }
  .iconguanbi {
    position: absolute;
    top: -24px;
    right: -24px;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    &:hover {
      color: @hoverColor;
    }
  }
  .iconxiaozhangjie {
    font-size: 12px;
  }
}
</style>
