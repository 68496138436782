<template>
  <section>
    <header class="chapter-edit__header">
      <h3 class="chapter-edit__title">文档编辑</h3>
      <div class="clear-doc" @click="clearDoc">
        <i class="iconfont iconshanchu_icon"></i>
        <el-button type="text">清空文档</el-button>
      </div>
    </header>
    <div class="docList">
      <div
        class="doc-item"
        v-for="doc in selectDocNames"
        :key="doc.document_id"
      >
        <i class="icon-ppt"></i>
        <span>{{ doc.doc_name }}</span>
      </div>
    </div>
    <div
      class="chapter-list"
      v-loading="loading"
      element-loading-text="加载中..."
    >
      <div v-for="(mainChapter, index) in chapterData" :key="index">
        <div class="main-chapter">
          <span class="main-chapter__page">{{
            `${mainChapter.document_id}-page${mainChapter.page}`
          }}</span>
          <span class="main-chapter__time">{{ mainChapter.created_at }}</span>
          <span class="main-chapter__title">{{ mainChapter.title }}</span>
        </div>
        <div
          class="sub-chapter"
          v-for="(subChapter, subIndex) in mainChapter.subsection"
          :key="subIndex"
        >
          <span class="sub-chapter__page">{{
            `${subChapter.document_id}-step${subChapter.step}`
          }}</span>
          <span class="sub-chapter__time">{{ subChapter.created_at }}</span>
          <span class="sub-chapter__title">{{ subChapter.title }}</span>
        </div>
      </div>
    </div>
    <div class="section__footer">
      <el-button type="primary" @click="preview" :disabled="canPreview">预览</el-button>
      <el-button @click="edit">编辑</el-button>
    </div>
    <preview-chapter ref="previewChapterDialog" :chapterData="chapterData" ></preview-chapter>
    <el-dialog
      title="提示"
      :visible.sync="isShowConfirmDialog"
      width="400px"
      center
      class="confirm-dialog"
    >
      <div class="confirm-dialog__content">
        <i class="iconfont iconjingshi"></i>
        <span class="confirm-dialog__content__tips">确认清除文档吗?</span>
      </div>
      <div class="dialog-footer">
        <el-button @click="closeConfirm">取 消</el-button>
        <el-button type="primary" @click="cancel">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import eventBus from '../event'
import previewChapter from './preview'
import { APPID, RECORDID } from '../mixin'
export default {
  components: { previewChapter },
  data() {
    return {
      chapterData: [],
      loading: true,
      isShowConfirmDialog: false,
      canPreview: true
    }
  },
  created() {
    this.accountId = this.$parent._data.accountId
    this.slectedDocIds = this.$route.params.doc_ids
    this.selectDocNames = this.$parent._data.selectDocNames || []
    this.timer = setTimeout(() => {
      this.getChapterData()
    }, 2000)
  },
  methods: {
    clearDoc() {
      this.isShowConfirmDialog = true
    },
    cancel() {
      let params = {
        app_id: APPID,
        vod_id: RECORDID
      }
      this.$fetch('delDoc', params)
        .then(() => {
          this.isShowConfirmDialog = false
          // eventBus.$emit('switchComponent', {
          //   componentName: 'selectDoc'
          // })
          this.$router.push({ name: 'select-doc' })
        })
        .catch(e => {
          this.$message.error(e.msg)
        })
    },
    closeConfirm() {
      this.isShowConfirmDialog = false
    },
    edit() {
      this.$router.push({
        name: 'chapter-edit',
        params: { isEdit: true, doc_ids: this.slectedDocIds }
      })
    },
    getChapterData() {
      let params = {
        app_id: APPID,
        vod_id: RECORDID
      }
      this.$fetch('getDocChapterEditInfo', params)
        .then(({ data }) => {
          this.loading = false
          this.chapterData = data.doc_titles
          this.canPreview = false
        })
        .catch(() => {
          this.loading = false
          this.canPreview = false
        })
    },
    preview() {
      eventBus.$emit('playerPause')
      this.$refs.previewChapterDialog.dialogShow()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.chapter-header {
  margin: 0 0 30px 0;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.chapter-edit__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  i {
    font-size: 16px;
    cursor: pointer;
  }
  .clear-doc {
    display: flex;
    align-items: center;
    span {
      padding-left: 5px;
    }
    &:hover i {
      color: @mainColor;
    }
  }

  &__title {
    margin: 0 0 30px 0;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}
.docList {
  .doc-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-bottom: 10px;
    .icon-ppt {
      display: block;
      width: 26px;
      height: 31px;
      margin-right: 10px;
      background: url('../img/ppt.png') no-repeat;
      background-size: cover;
    }
  }
}
.chapter-list {
  padding: 20px;
  margin-bottom: 30px;
  min-height: 200px;
  max-height: calc(100vh - 415px);
  overflow-y: auto;
  background: #ededed;
  .main-chapter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    // font-weight: bold;
    &__page {
      display: inline-block;
      width: 140px;
    }
    &__time {
      display: inline-block;
      width: 68px;
      margin-left: 30px;
    }
    &__title {
      flex: 1;
      margin-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .sub-chapter {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 30px;
    height: 32px;
    box-sizing: border-box;
    &__time {
      margin-left: 20px;
    }
    &__title {
      margin-left: 20px;
      flex: 1;
      margin-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.confirm-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .iconfont {
      color: #f01c1f;
      font-size: 40px;
    }
    &__tips {
      margin-top: 15px;
      color: #666;
    }
  }
  .dialog-footer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    .el-button {
      width: 90px;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}
</style>
